import { useUiStore } from '~/stores/ui'

export default function () {
  const nuxtApp = useNuxtApp()
  const uiStore = useUiStore()
  const appHeaderElement = ref(null)

  watch(appHeaderElement, async (newHeader) => {
    const headerRect = newHeader?.getBoundingClientRect()
    if (headerRect) {
      uiStore.appHeaderOffset = headerRect?.height
    }
  })

  const disableSticky = computed(() => {
    // these routes have their own internal sticky headers
    const routeNames = [
      'Course',
      'Offering',
      'study-seeker-id',
      'IntCourse',
      'IntPathway'
    ]

    return (
      nuxtApp._route.name && routeNames.includes(nuxtApp._route.name.toString())
    )
  })

  onMounted(() => {
    const handleThrottledScroll = nuxtApp.$throttle(handleScroll, 150)

    window?.addEventListener('scroll', handleThrottledScroll, {
      passive: true
    })

    // if (appHeader && appHeader.value) {
    //   const headerRect = appHeader.value.getBoundingClientRect()
    //   uiStore.appHeaderOffset = headerRect?.height
    // }
    // const headerHeight = this.$refs.appHeader?.getBoundingClientRect().height
    // this.setHeaderOffset(headerHeight)
  })

  function handleScroll() {
    uiStore.setScrolledStatus({
      isScrolled: window?.scrollY > 0,
      value: window?.scrollY
    })
  }

  return { disableSticky }
}
